import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IAverageHourlyRate } from 'src/apis/types/averageHourlyRateAPI';

const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_URL_PATH =
  '/api/v2/insights-service/average-hourly-rate';
const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_MSW_STATUS_KEY = 'getAverageHourlyRateAPI';

const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY = 'GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY';

const getAverageHourlyRateReportingWidget = (): Promise<IAverageHourlyRate> =>
  getFetch({
    path: GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_URL_PATH,
    key: GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetAverageHourlyRateReportingWidget = () => {
  const { data, ...restProps } = useQuery([GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY], () =>
    getAverageHourlyRateReportingWidget(),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      currencyAbb: data.properties.systemCurrencyAbb,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    currencyAbb: '',
    ...restProps,
  };
};
