import { IframeHTMLAttributes, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useMobileNavigationDispatch } from 'src/stores/MobileNavigationStore';
import { useMainSearchDispatch } from 'src/stores/MainSearchStore';
import { usePageDispatch } from 'src/stores/PageStore';
import { APP_URL } from 'src/consts/cookies';
import { Helmet } from 'react-helmet-async';
import cx from 'classnames';
import { shortcutElementNames } from 'src/ShortcutSystem';
import { REPORTING_WIDGET_HAS_ACCESS_KEY } from 'src/apis/reportingWidgetsAPI/get/all/getAllAPI';
import styles from './LegacyPage.module.scss';
import LoadingSpinner from '../LoadingSpinner';

const generateIframeKey = (iframeUrl: string, iframeKey: number) =>
  `${iframeUrl}${iframeKey || ''}`;

const generateIframeUrl = (
  customerSiteUrl: string,
  pathname: string,
  search: string,
  legacyPageSrc?: string,
) => {
  const iframeUrl =
    legacyPageSrc == null
      ? `${customerSiteUrl}${pathname}${search}`
      : `${customerSiteUrl}/${legacyPageSrc}`;

  return iframeUrl.includes('?') ? `${iframeUrl}&legacypage=true` : `${iframeUrl}?legacypage=true`;
};

export const generateIframeUrlForTest = generateIframeUrl;
export const generateIframeKeyForTest = generateIframeKey;

interface LegacyPageProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  automationId: string;
  id?: string;
  name?: string;
  src?: string;
  isInModal?: boolean;
}

export const LegacyPage = ({
  automationId,
  src,
  id = 'timelog-legacy-page-iframe',
  name = 'timelog-legacy-page-iframe',
  isInModal,
  ...props
}: LegacyPageProps) => {
  const { pathname, search } = useLocation();
  const pageDispatch = usePageDispatch();
  const { push } = useHistory();
  const customerSiteUrl = Cookies.get(APP_URL) ?? '';

  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [iframeKey, setIFrameKey] = useState(0);

  const [iframeUrl, setIframeUrl] = useState(
    generateIframeUrl(customerSiteUrl, pathname, search, src),
  );

  const mobileNavigationDispatch = useMobileNavigationDispatch();
  const mainSearchDispatch = useMainSearchDispatch();

  useEffect(() => {
    // Reset the focus state in the navigation after redirect to iframe url
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    // Close the mobile navigation before redirecting to TLP page
    if (mobileNavigationDispatch) {
      mobileNavigationDispatch({
        type: 'CLOSED',
      });
    }

    document.body.classList.add(styles.IsLegacy);

    return function cleanup() {
      document.body.classList.remove(styles.IsLegacy);
    };
  }, [mobileNavigationDispatch, iframeUrl]);

  useEffect(() => {
    setIframeUrl(generateIframeUrl(customerSiteUrl, pathname, search, src));
  }, [customerSiteUrl, pathname, search, src]);

  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const {
        data: { eventId, url, title, timelogCustomerSegmentation, flagValue },
      } = event;

      switch (eventId) {
        case 'urlClicked': {
          /*
           * If is redirect to external url, use window.location.href
           */
          if (url.startsWith('http') && !url.startsWith(customerSiteUrl)) {
            window.location.href = url;
          } else {
            setIsLoading(true);
            push(url.replace(customerSiteUrl, ''));
            setIFrameKey(new Date().valueOf());
          }
          break;
        }
        case 'reloadSession': {
          window.location.href = decodeURIComponent(url);
          break;
        }
        case 'iframeUrlUpdated': {
          if (url.toLowerCase() !== iframeUrl.toLowerCase() && url.startsWith(customerSiteUrl)) {
            window.history.replaceState(
              window.history.state,
              title,
              url
                .replace(customerSiteUrl, '')
                .replace('?legacypage=true', '')
                .replace('&legacypage=true', ''),
            );
          }
          break;
        }
        case 'title': {
          setPageTitle(title);
          break;
        }
        case 'f2Shortcut': {
          if (mainSearchDispatch) {
            mainSearchDispatch({
              type: 'TOGGLE_SEARCH',
            });

            // main search input
            if (document.getElementsByName(shortcutElementNames.mainSearch).length > 0) {
              document.getElementsByName(shortcutElementNames.mainSearch)[0].focus();
            }
          }
          break;
        }
        case 'updateTimelogCustomerSegmentation': {
          window.timelogcustomersegmentation = timelogCustomerSegmentation;
          break;
        }
        case 'reportingManagerUpdated': {
          localStorage.setItem(REPORTING_WIDGET_HAS_ACCESS_KEY, flagValue);
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener('message', handleIframeEvent as EventListener);

    return () => {
      window.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [
    push,
    iframeUrl,
    setPageTitle,
    customerSiteUrl,
    pageDispatch,
    pathname,
    search,
    mainSearchDispatch,
    mobileNavigationDispatch,
  ]);

  return (
    <>
      {pageTitle !== '' && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}

      <iframe
        loading="lazy"
        key={generateIframeKey(iframeUrl, iframeKey)}
        id={id}
        data-automation-id={automationId}
        name={name}
        src={iframeUrl}
        title={pageTitle}
        width="100%"
        scrolling="yes"
        className={cx(styles.LegacyIframe, { [styles.IsInModal]: isInModal })}
        onLoad={() => setIsLoading(false)}
        {...props}
      />

      {isLoading && <LoadingSpinner />}
    </>
  );
};
