import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IProjectEconomyHealth } from 'src/apis/types/projectEconomyHealthAPI';

const GET_PROJECT_ECONOMY_HEALTH_WIDGET_URL_PATH =
  '/api/v2/insights-service/project-economy-health-invoice';
const GET_PROJECT_ECONOMY_HEALTH_WIDGET_MSW_STATUS_KEY = 'getProjectEconomyHealthAPI';

const GET_PROJECT_ECONOMY_HEALTH_WIDGET_KEY = 'GET_PROJECT_ECONOMY_HEALTH_REPORTING_WIDGET_KEY';

const getProjectEconomyHealthReportingWidget = (): Promise<IProjectEconomyHealth> =>
  getFetch({
    path: GET_PROJECT_ECONOMY_HEALTH_WIDGET_URL_PATH,
    key: GET_PROJECT_ECONOMY_HEALTH_WIDGET_MSW_STATUS_KEY,
  });

export const useGetProjectEconomyHealthReportingWidget = () => {
  const { data, ...restProps } = useQuery([GET_PROJECT_ECONOMY_HEALTH_WIDGET_KEY], () =>
    getProjectEconomyHealthReportingWidget(),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    ...restProps,
  };
};
