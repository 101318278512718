import { Route, Redirect } from 'react-router-dom';
import { IMenusPropertiesWithPagePath, ISubMenusWithPagePath } from 'src/apis/types/menusAPI';

interface ISystemAdminRouter {
  path: string;
  systemAdminMenu: IMenusPropertiesWithPagePath[];
  children: JSX.Element;
}

export const SystemAdministrationRouter = ({
  path,
  systemAdminMenu,
  children,
}: ISystemAdminRouter) => {
  const isPageExists = systemAdminMenu.some((obj: IMenusPropertiesWithPagePath) =>
    obj.subMenus?.some((subMenu: ISubMenusWithPagePath) => subMenu.pagePath === path),
  );

  return (
    <Route
      path={path}
      render={({ location }) =>
        isPageExists ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/error',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
