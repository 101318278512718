import { Spinner, THorizontalAlignment, useGetLocale } from '@timelog/ui-library';
import {
  convertDateToLocaleString,
  getDateStringFromSiteLocale,
  getDateWithZeroOffsetFromDateString,
  getLanguageFromSiteLocale,
} from 'src/utils/date';
import { useCallback, useMemo } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import {
  IResourcePlannerColumn,
  IResourcePlannerItem,
  IResourcePlannerPeriodValueString,
} from 'src/apis/types/resourcePlannerAPI';
import postResourcePlannerChange from 'src/apis/resourcePlannerAPI/post/postResourcePlannerChange';
import { ColumnDef, Getter } from '@tanstack/react-table';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetGroupByProject,
} from 'src/apis/resourcePlannerAPI';

import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import { translationAnyText } from 'src/utils/translation';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { useQueryClient } from '@tanstack/react-query';
import { useGetPartialGroupByProject } from 'src/apis/resourcePlannerAPI/get/getGroupByProjectAPI';
import { GeneralColumnRenderer } from '../GeneralColumnRenderer';
import TotalColumnRenderer from '../TotalColumnRenderer';
import camelToPascal from '../../helper/camelToPascal';
import generatePeriodLabel from '../../helper/generatePeriodLabel';
import { RTRow, RTColumn, RPRow } from '../../types/resourcePlanner';
import {
  RPEmployeeViewInitialExpandedRowsStateKey,
  RPinvalidateOldQueryOnViewOptionChange,
  RPProjectViewInitialExpandedRowsStateKey,
  RPSlectedFilterListStateKey,
} from '../../localStorageKeys';
import ExcessiveOrNoData from '../ExcessiveOrNoData';
import ResourceTableGroupedByProjectTable from './ResourceTableGroupedByProjectTable';
import { findRowById, getExpandedRowIds, setExpandedRowIds } from '../../helper/expandedRow';
import { getShimmerRows } from '../../helper/generateShimmerRows';
import { getFixedColumnTitleIdentifier } from '../../helper/getFixedColumnTooltip';
import { RPShimmerRowCount } from '../../constants';

const shimmerRowId = '-9999999';

const fixedColumns: IResourcePlannerColumn[] = [
  {
    identifier: 'status',
  },
  {
    identifier: 'startsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'endsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'budget',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalBooked',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalActualWorkload',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'notPlanned',
    alignment: 'right',
    format: 'number',
  },
];

interface ResourceTableGroupedByProjectProps {
  selectedViewOptions: {
    [key: string]: string;
  };
}

const getTranslatedText = (
  t: TFunction<'resourcePlanner', undefined>,
  unitType: string,
  identifier: string,
) => {
  if (identifier === 'budget') {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}ProjectView`,
    );
  }
  if (
    identifier === 'totalBooked' ||
    identifier === 'totalActualWorkload' ||
    identifier === 'notPlanned'
  ) {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}`,
    );
  }
  return translationAnyText(t, `ColumnLabel${camelToPascal(identifier)}`);
};

export default ({ selectedViewOptions }: ResourceTableGroupedByProjectProps) => {
  const locale = useGetLocale();
  const { t } = useTranslation('resourcePlanner');
  const qc = useQueryClient();
  const { filterQueryObj, isRequiredQuery } = useFilterStore();
  const filterDispatch = useFilterDispatch();

  if (isRequiredQuery) {
    if (filterDispatch) filterDispatch({ type: 'DEACTIVATE_RENDER' });
  }

  // get filter from localstorage and compare with newest filter. If change reset expanded ids
  if (JSON.stringify(filterQueryObj) !== localStorage.getItem(RPSlectedFilterListStateKey)) {
    localStorage.removeItem(RPProjectViewInitialExpandedRowsStateKey);
    localStorage.removeItem(RPEmployeeViewInitialExpandedRowsStateKey);
  }
  localStorage.setItem(RPSlectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const { isLoading, isEmpty, periods, children, responseType } = useGetGroupByProject(
    { selectedFilterList: filterQueryObj },
    selectedViewOptions,
  );

  const getRowsByParentId = useGetPartialGroupByProject(
    { selectedFilterList: filterQueryObj },
    selectedViewOptions,
  );

  const { mutate: postChange } = postResourcePlannerChange();
  const unitType = selectedViewOptions['unit-types'];
  // eslint-disable-next-line
  console.log("unitType", unitType);
  // eslint-disable-next-line
  console.log("selectedViewOptions", selectedViewOptions);
  const reportingType = selectedViewOptions['reporting-types'];
  const { currentLanguage } = useGetCurrentLanguage();

  const siteLanguage = getLanguageFromSiteLocale(locale);

  const columns = useMemo<ColumnDef<RPRow>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => t('ColumnLabelName'),
        cell: ({ getValue }: { getValue: Getter<string> }) => getValue(),
      },
      ...fixedColumns.map((fixedColumn) => ({
        id: fixedColumn.identifier,
        accessorKey: fixedColumn.identifier,
        meta: {
          format: fixedColumn?.format,
          alignment: fixedColumn?.alignment,
          dividerBorderLeft: fixedColumn.identifier === 'budget',
          title: translationAnyText(t, getFixedColumnTitleIdentifier(fixedColumn.identifier)),
        },
        header: () => getTranslatedText(t, unitType, fixedColumn.identifier),
        cell: ({
          row,
          column,
          getValue,
        }: {
          row: RTRow;
          column: RTColumn;
          getValue: Getter<string>;
        }) => {
          const cellValue = getValue();

          const value =
            column.columnDef.meta?.format === 'date' && cellValue !== undefined
              ? convertDateToLocaleString(new Date(cellValue), locale)
              : cellValue;

          const cellDisplayValue = GeneralColumnRenderer({
            row,
            id: column.columnDef.id,
            format: column.columnDef.meta?.format,
            value,
          });

          return cellDisplayValue;
        },
      })),
      ...periods.map((periodColumn, index) => ({
        id: `periodCol_${periodColumn.identifier}`,
        accessorKey: periodColumn.identifier,
        accessorFn: (row: RPRow) => row.values[periodColumn.identifier],
        meta: {
          periodIndex: index,
          isPeriod: true,
          editable: true,
          alignment: 'right' as THorizontalAlignment,
          startsAt: periodColumn.startsAt,
          endsAt: periodColumn.endsAt,
          dividerBorderLeft: index === 0,
          title:
            selectedViewOptions['period-types'] !== 'day'
              ? `${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.startsAt),
                  siteLanguage,
                )} - ${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.endsAt),
                  siteLanguage,
                )}`
              : '',
        },
        header: () =>
          generatePeriodLabel(
            periodColumn.identifier,
            selectedViewOptions['period-types'],
            currentLanguage,
            t,
          ),
        cell: ({ getValue }: { getValue: Getter<IResourcePlannerPeriodValueString> }) =>
          getValue().displayValue,
      })),
      {
        id: 'total',
        accessorKey: 'total',
        meta: {
          type: 'total',
          alignment: 'right' as THorizontalAlignment,
        },
        header: () => t('ColumnLabelTotal'),
        cell: ({ row }) =>
          TotalColumnRenderer({
            displayValue: row.original.total?.displayValue,
            unitType,
          }),
      },
    ],
    [selectedViewOptions, periods, t, currentLanguage, unitType, locale, siteLanguage],
  );

  const onCellValueChange = useCallback(
    (row: RTRow, column: RTColumn, value: string) => {
      const ids = row.id.split('.').map((i) => parseInt(i, 10));
      const cachedDataKey = [GROUP_BY_PROJECT_KEY, selectedViewOptions || {}, filterQueryObj || {}];

      const cachedData:
        | { model: { properties: { children: IResourcePlannerItem[] } } }
        | undefined = qc.getQueryData(cachedDataKey);

      if (cachedData) {
        const findRow = (allRows: RPRow[], indices: number[]): RPRow => {
          const index = indices.shift();

          if (index === undefined) {
            return allRows[0];
          }

          const currentRow = allRows[index];

          if (currentRow === undefined) {
            return allRows[0];
          }

          if (indices.length && currentRow.children && currentRow.children.length) {
            return findRow(currentRow.children, indices);
          }

          return currentRow;
        };

        const updatedRow = findRow(cachedData.model.properties.children, ids);

        if (!column.id) {
          throw new Error('Could not find updated column');
        }

        const periodColumnIdSubstring = column.id.substring(
          column.id.indexOf('_') + 1,
          column.id.length,
        );

        const updatedCell = updatedRow.values[periodColumnIdSubstring];

        updatedRow.values[periodColumnIdSubstring] = {
          ...updatedCell,
          displayValue: value,
        };

        qc.setQueryData(cachedDataKey, cachedData);
      }

      const { resourceId, workItemId } = row.original;
      const startsAt = column.columnDef.meta?.startsAt!;
      const endsAt = column.columnDef.meta?.endsAt!;

      postChange(
        {
          resourceId,
          workItemId,
          unitType,
          value,
          startsAt,
          endsAt,
        },
        {
          onSettled: () => {
            qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
            qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
            qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
          },
        },
      );
    },
    [filterQueryObj, postChange, qc, selectedViewOptions, unitType],
  );

  const toggleCurrentRowAndUpdateState = (storageKey: string, row: any) => {
    const expandedRowIds = getExpandedRowIds(storageKey);
    const toggledRow = expandedRowIds.find((item) => item.tableRowId === row.id);
    if (toggledRow) {
      toggledRow.isExpanded = !row.getIsExpanded();
    } else {
      expandedRowIds.push({
        tableRowId: row.id,
        originalId: row.original.id,
        isExpanded: !row.getIsExpanded(),
      });
    }
    setExpandedRowIds(
      storageKey,
      expandedRowIds.filter((item) => item.isExpanded),
    );
    localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');
    row.toggleExpanded();
  };

  const handleOnToggleRowCallback = useCallback(
    async (row) => {
      const cachedDataKey: any[] = [
        GROUP_BY_PROJECT_KEY,
        selectedViewOptions || {},
        filterQueryObj || {},
      ];

      const storageKey = RPProjectViewInitialExpandedRowsStateKey;
      const initialChilds = row.original?.children ? row.original?.children : undefined;
      const isShimmerChilds =
        initialChilds !== undefined &&
        initialChilds.length > 0 &&
        initialChilds[0].id === shimmerRowId;
      const isToExpandCurrentRow = !row.getIsExpanded();
      toggleCurrentRowAndUpdateState(storageKey, row);

      if (
        isToExpandCurrentRow &&
        (initialChilds === undefined || initialChilds.length === 0 || isShimmerChilds)
      ) {
        const cachedData =
          qc.getQueryData(cachedDataKey) !== undefined &&
          JSON.stringify(qc.getQueryData(cachedDataKey))
            ? JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)))
            : undefined;
        const modifiedRow = findRowById(cachedData?.model.properties.children, row.original.id);
        const modifiedRowChilds = modifiedRow?.children ? modifiedRow.children : undefined;
        let rawChildRowsFromServer;
        let didDataFetch = false;

        const isShimmerRow =
          modifiedRowChilds !== undefined &&
          modifiedRowChilds.length > 0 &&
          modifiedRowChilds[0].id === shimmerRowId;

        if (modifiedRowChilds === undefined || modifiedRowChilds.length === 0 || isShimmerRow) {
          try {
            getRowsByParentId.reset();
            if (modifiedRow) {
              modifiedRow.children = getShimmerRows(shimmerRowId, RPShimmerRowCount); // add shimmerows
              qc.setQueryData(cachedDataKey, cachedData);
            }
            rawChildRowsFromServer = await getRowsByParentId.mutateAsync(row.original.id);
            didDataFetch = true;
          } catch (e) {
            // TODO: Should we notify the user that an error has occurred?
            if (modifiedRow) modifiedRow.children = initialChilds; // making sure to remove shimmer rows
            qc.setQueryData(cachedDataKey, cachedData);
            return;
          }
        }

        let childRows;
        if (didDataFetch) {
          childRows = rawChildRowsFromServer?.model?.properties?.children
            ? rawChildRowsFromServer?.model?.properties?.children
            : undefined;

          // fix for single row expand on lowest level
          if (rawChildRowsFromServer?.model?.properties?.children[0]?.id === modifiedRow?.id) {
            childRows = rawChildRowsFromServer?.model?.properties?.children[0].children
              ? rawChildRowsFromServer?.model?.properties?.children[0].children
              : undefined;
          }
        } else {
          childRows = modifiedRowChilds;
        }

        const cachedDataToBeUpdated =
          qc.getQueryData(cachedDataKey) !== undefined &&
          JSON.stringify(qc.getQueryData(cachedDataKey)) !== undefined
            ? JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)))
            : undefined;
        const modifiedRowToBeUpdated = findRowById(
          cachedDataToBeUpdated?.model.properties.children,
          row.original.id,
        );

        if (modifiedRowToBeUpdated && childRows && childRows.length) {
          modifiedRowToBeUpdated.children = childRows;
        } else if (modifiedRowToBeUpdated) {
          // this should not be te case, as the rows without children should have a "canExpand" property equal false
          modifiedRowToBeUpdated.children = modifiedRowChilds;
        }
        await qc.setQueryData(cachedDataKey, cachedDataToBeUpdated);
      }
    },
    [filterQueryObj, getRowsByParentId, qc, selectedViewOptions],
  );

  const setExpandedRowsAndAddShimmer = (expandedIdsString: string, rows: any) => {
    if (rows) {
      const cachedDataKey = [GROUP_BY_PROJECT_KEY, selectedViewOptions || {}, filterQueryObj || {}];

      const cachedData:
        | { model: { properties: { children: IResourcePlannerItem[] } } }
        | undefined = JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)));

      const expandedIds = expandedIdsString.split(',');

      for (let i = 0; i < rows.length; i += 1) {
        if (expandedIds.findIndex((eId: string) => eId === rows[i].original.id)) {
          const modifiedRow = findRowById(
            cachedData?.model.properties.children,
            rows[i].original.id,
          );
          if (modifiedRow) {
            modifiedRow.children = getShimmerRows(shimmerRowId, RPShimmerRowCount); // add shimmerows
          }
        }
      }
      qc.setQueryData(cachedDataKey, cachedData);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (responseType === 'ExcessiveData' || (isEmpty && responseType === 'NoData')) {
    return <ExcessiveOrNoData type={responseType} />;
  }

  return (
    <ResourceTableGroupedByProjectTable
      columns={columns}
      data={children}
      onCellValueChange={onCellValueChange}
      unitType={unitType}
      reportingType={reportingType}
      selectedViewOptions={selectedViewOptions}
      handleOnToggleRow={handleOnToggleRowCallback}
      setExpandedRows={setExpandedRowsAndAddShimmer}
    />
  );
};
