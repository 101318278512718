export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        CustomerId: 1,
        CustomerNo: '-',
        CustomerName: 'Intern',
        ProjectId: 1,
        ProjectNo: 'P20.0001',
        ProjectName: '_Administration',
        SumBudgetHours: 0,
        SumHoursSpent: 683,
        HoursRemaining: -683,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 1,
        CustomerNo: '-',
        CustomerName: 'Intern',
        ProjectId: 2,
        ProjectNo: 'P20.0002',
        ProjectName: '_Salgsarbejde',
        SumBudgetHours: 0,
        SumHoursSpent: 279.5,
        HoursRemaining: -279.5,
        CompletionRate: 0,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 6,
        CustomerNo: '201004',
        CustomerName: 'Bada ApS',
        ProjectId: 3,
        ProjectNo: 'P20.0003',
        ProjectName: 'BAD - Rådgivning EU-godkendelse (TMSA DKK)',
        SumBudgetHours: 130,
        SumHoursSpent: 105,
        HoursRemaining: 25,
        CompletionRate: 80.77,
        ProgressStatus: 'Success',
      },
      {
        CustomerId: 4,
        CustomerNo: '201002',
        CustomerName: 'Freshmaker AB (SEK)',
        ProjectId: 4,
        ProjectNo: 'P20.0004',
        ProjectName: 'FRE - Advisory on EU approval (TMSA SEK)',
        SumBudgetHours: 114,
        SumHoursSpent: 108,
        HoursRemaining: 6,
        CompletionRate: 94.74,
        ProgressStatus: 'Success',
      },
    ],
    Type: 'ProjectEconomyHealth',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/project-economy-health-invoice-widget-data?siteid=520abe0d-c7e2-4a73-a5f2-c349c48e4e25',
      Rel: 'self',
    },
  ],
};
