import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IPlannedAbsence } from 'src/apis/types/plannedAbsenceAPI';

const GET_PLANNED_ABSENCE_REPORTING_WIDGET_URL_PATH = '/api/v2/insights-service/planned-absence';
const GET_PLANNED_ABSENCE_REPORTING_WIDGET_MSW_STATUS_KEY = 'getPlannedAbsenceAPI';

const GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY = 'GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY';

const getPlannedAbsenceReportingWidget = (): Promise<IPlannedAbsence> =>
  getFetch({
    path: GET_PLANNED_ABSENCE_REPORTING_WIDGET_URL_PATH,
    key: GET_PLANNED_ABSENCE_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetPlannedAbsenceReportingWidget = () => {
  const { data, ...restProps } = useQuery([GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY], () =>
    getPlannedAbsenceReportingWidget(),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    ...restProps,
  };
};
